import React from "react"
import "./403.scss"
import { useNavigate } from "react-router-dom"
import { Result, Button } from "antd"
import { JudoContainer } from "frontend/components/Layout/JudoContainer/JudoContainer"
import { useTranslation } from "react-i18next"
import { TranslationConstants } from "common/constants/translations"
import { HomeOutlined, ReloadOutlined } from "@ant-design/icons"
import { PathConstants } from "../../../common/constants/paths"

function Page403() {
  const navigate = useNavigate()

  const { t } = useTranslation()

  return (
    <Result
      status="403"
      title="403"
      subTitle={t(TranslationConstants.i18n_UNAUTHORIZED_MESSAGE)}
      extra={
        <div className={"extra-buttons-div-404"}>
          <Button type="primary" onClick={() => navigate(PathConstants.DASHBOARD)} icon={<HomeOutlined />}>
            {t(TranslationConstants.i18n_BACK_TO_HOME)}
          </Button>
          <Button type="primary" onClick={() => navigate(0)} icon={<ReloadOutlined />}>
            {t(TranslationConstants.i18n_RELOAD_PAGE)}
          </Button>
        </div>
      }
    />
  )
}

export { Page403 }
