import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import translationEN from "./locales/en/translation.json"
import translationIT from "./locales/it/translation.json"
import { LanguageConstants } from "../../common/constants/languages"

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false
  }
}

const resources = {
  en: {
    translation: translationEN,
  },
  it: {
    translation: translationIT,
  },
}

i18n.use(initReactI18next).init({
  resources,
  returnNull: false,
  fallbackLng: LanguageConstants.DEFAULT_LANGUAGE,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
})

export default i18n

// export function changeLanguage(language) {
//   i18n.changeLanguage(language)
// }
