export const PathConstants = {
  DASHBOARD: "/",
  SELECT_MATCH: "/event-tagging/select-match",
  MATCH_UPLOAD_VIDEO: "/event-tagging/matches/:matchId/upload-video",
  MATCH_UPLOAD_VIDEOS: "/event-tagging/matches/upload-videos",
  MATCH_TAG: "/event-tagging/matches/:matchId/tag",
  MATCH_TAG_MATCHES: "/event-tagging/matches/tag",
  COMPETITION_EVENT_EXPORT_CSV: "/event-tagging/competitions/export-event-csv",
  VIDEO_ANALYSIS_MATCH_EVENT_ANALYSIS: "/video-analysis/matches/:matchId/event-analysis",
  VIDEO_ANALYSIS_MATCHES: "/video-analysis/matches",
  JUDODATA_MATCH_VIDEO: "/judo-data/matches/:matchId/video",
  JUDODATA_EVENTS_EXPLORE: "/judo-data/events/explore",
  CMS_ATHLETES_TABLE_PAGE: "/cms/athletes",
  CMS_ATHLETES_CREATE_PAGE: "/cms/athletes/create",
  CMS_ATHLETES_UPDATE_PAGE: "/cms/athletes/:athleteId/update",
  CMS_REFEREES_TABLE_PAGE: "/cms/referees",
  CMS_REFEREES_CREATE_PAGE: "/cms/referees/create",
  CMS_REFEREES_UPDATE_PAGE: "/cms/referees/:refereeId/update",
  CMS_COMPETITIONS_TABLE_PAGE: "/cms/competitions",
  CMS_COMPETITIONS_CREATE_PAGE: "/cms/competitions/create",
  CMS_COMPETITIONS_UPDATE_PAGE: "/cms/competitions/:competitionId/update",
  CMS_LAMP_COMPETITIONS: "/cms/lamp/competitions",
  CMS_LAMP_COMPETITIONS_EDIT_PAGE: "/cms/lamp/competitions/:competitionId/edit",
  CMS_LAMP_COMPETITIONS_WEIGHT_CLASS_POPULATION_PAGE:
    "/cms/lamp/competitions/:competitionId/weight-class/:weightClassId/populate",
  CMS_MATCHES_TABLE_PAGE: "/cms/matches",
  CMS_MATCHES_CREATE_PAGE: "/cms/matches/create",
  CMS_MATCHES_UPDATE_PAGE: "/cms/matches/:matchId/update",
  USER_PROFILE: "/profile",
} as const
