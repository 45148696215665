import React, { useEffect, useState } from "react"
import "./JudoContainer.scss"
import { Layout } from "antd"
import { JudoSider } from "frontend/components/Layout/JudoSider/JudoSider"
import { DefaultHeader } from "frontend/components/Layout/DefaultHeader/DefaultHeader"
import { DefaultFooter } from "frontend/components/Layout/DefaultFooter/DefaultFooter"
import { LayoutConstants } from "common/constants/layout"
import { useNavigate } from "react-router-dom"
import { ErrorConstants } from "../../../../common/constants/errors"

const JudoContainer: React.FC<{
  siderCollapsed?: boolean
  header?: React.ReactNode
  footer?: React.ReactNode
  showHeader?: boolean
  children?: React.ReactNode
  isAllowed?: boolean
}> = ({ showHeader = true, siderCollapsed = undefined, header, footer, children, isAllowed = true }) => {
  if (!isAllowed) {
    throw Error(ErrorConstants.UNAUTHORIZED)
  }

  const [isSiderCollapsed, setIsSiderCollapsed] = useState<boolean>(false)
  const [isResponsive, setIsResponsive] = useState<boolean>(false)

  useEffect(() => {
    if (siderCollapsed !== undefined) {
      setIsSiderCollapsed(siderCollapsed)
    }
  }, [siderCollapsed])

  return (
    <Layout className={"judo-layout"}>
      <JudoSider
        collapsed={isSiderCollapsed}
        isResponsive={isResponsive}
        onCollapse={(collapsed, type) => {
          setIsSiderCollapsed(collapsed)

          let newIsResponsive = isResponsive
          if (type === "responsive") {
            newIsResponsive = !newIsResponsive
          }
          setIsResponsive(newIsResponsive)
        }}
      />
      <Layout className="site-layout">
        {showHeader && (
          <div
            // style={{
            //   paddingLeft: isSiderCollapsed
            //     ? LayoutConstants.JUDO_COLLAPSED_SIDER_WIDTH
            //     : LayoutConstants.JUDO_SIDER_WIDTH,
            // }}
            className={"judo-header"}
          >
            {header || <DefaultHeader />}
          </div>
        )}
        <div
          style={{
            // marginLeft: isSiderCollapsed
            //   ? LayoutConstants.JUDO_COLLAPSED_SIDER_WIDTH
            //   : LayoutConstants.JUDO_SIDER_WIDTH,
            height: showHeader ? "calc(100vh - 55px)" : "100vh",
            marginTop: showHeader ? "55px" : "0",
          }}
          className={"judo-content-div"}
        >
          <div className={"judo-content-inner-div"}>{children}</div>
        </div>
        {footer || <DefaultFooter />}
      </Layout>
    </Layout>
  )
}

export { JudoContainer }
