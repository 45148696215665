import React, { useEffect, useState } from "react"
import "./App.scss"
import "bootstrap/dist/css/bootstrap.css"
import { Route, Routes } from "react-router-dom"
import routes from "frontend/routes"
import { Page404 } from "./views/404/404"
import "./languages/index"
import i18n from "frontend/languages/index"
import { TranslationConstants } from "common/constants/translations"
import { AuthAPI } from "./apis/AuthAPI"
import { Login } from "./views/Login/Login"
import { AuthContextData, AuthContext } from "./contexts/auth"
import { useLocation } from "react-router"
import { Modal } from "antd"
const loadingFn = () => <div className="animated fadeIn pt-3">{i18n.t(TranslationConstants.i18n_LOADING)}</div>

export function App() {
  const [loading, setLoading] = useState<boolean>(true)
  const [isLogged, setIsLogged] = useState<boolean>(false)

  const [authContext, setAuthContext] = useState<AuthContextData>({
    user: undefined,
  })

  const location = useLocation()

  useEffect(() => {
    if (!isLogged && location !== undefined) {
      // if it is not a public page
      if (!location.pathname.includes("/judo-data/")) {
        setLoading(true)

        AuthAPI.verifyToken()
          .then((kamaVerifyTokenOutput) => {
            setAuthContext(kamaVerifyTokenOutput)
            setIsLogged(true)
          })
          .catch(() => {
            setIsLogged(false)
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        setLoading(false)
        setIsLogged(true)
      }
    }
  }, [location])

  useEffect(() => {
    Modal.destroyAll()
  }, [location])

  let page = <>{loadingFn()}</>

  if (!loading && authContext) {
    if (!isLogged) {
      page = <Login />
    } else {
      page = (
        <AuthContext.Provider value={authContext}>
          <Routes>
            {routes.map((route, i) => {
              return route.component ? <Route key={i} path={route.path} element={<route.component />} /> : null
            })}
            <Route path={"*"} element={<Page404 />} />
          </Routes>
        </AuthContext.Provider>
      )
    }
  }

  return <React.Suspense fallback={loadingFn()}>{page}</React.Suspense>
}
