import React, { useEffect, useState } from "react"
import { Button, Card, Input, Spin } from "antd"
import { Alert, Col, Row } from "react-bootstrap"
import * as emailValidator from "email-validator"
import { AuthAPI } from "frontend/apis/AuthAPI"
import "./LoginForm.scss"
import { useTranslation } from "react-i18next"
import { TranslationConstants } from "common/constants/translations"
import { InputErrorMessage } from "../InputErrorMessage/InputErrorMessage"

function LoginForm({ onLoginCompleted }: { onLoginCompleted: () => void }) {
  const { t } = useTranslation()

  const [loading, setLoading] = useState<boolean>(false)

  const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false)
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")

  const [failed, setFailed] = useState<boolean>(false)

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === "Enter") {
        event.preventDefault()

        performLogin()
      }
    }
    if (document) {
      document.addEventListener("keydown", listener)

      return () => {
        document.removeEventListener("keydown", listener)
      }
    }
  }, [email, password])

  const performLogin = () => {
    if (!email || !password || !emailValidator.validate(email)) {
      setShowValidationErrors(true)

      return null
    } else {
      setLoading(true)

      AuthAPI.login({
        email: email.trim(),
        password: password,
      })
        .then(() => {
          onLoginCompleted()
        })
        .catch(() => {
          setFailed(true)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    return null
  }

  return (
    <Card title={t(TranslationConstants.i18n_RESERVED_AREA)} className={"login-form"}>
      <Spin spinning={loading}>
        <Col>
          <Row>
            <Col>
              {failed && <Alert variant={"danger"}>{t(TranslationConstants.i18n_EMAIL_OR_PASSWORD_WRONG)}</Alert>}
            </Col>
          </Row>
          <Row className={"label-row"}>
            <Col>
              <span className={"label"}>{t(TranslationConstants.i18n_EMAIL)}</span>
            </Col>
          </Row>
          <Row className={"input-row"}>
            <Col>
              <Input
                placeholder={t(TranslationConstants.i18n_EMAIL)}
                value={email}
                onChange={(e) => {
                  setFailed(false)
                  setShowValidationErrors(false)
                  setEmail(e.target.value)
                }}
              />
              <InputErrorMessage
                show={showValidationErrors && !email}
                message={t(TranslationConstants.i18n_MANDATORY_FIELD)}
              />
              <InputErrorMessage
                show={showValidationErrors && !!email && !emailValidator.validate(email)}
                message={t(TranslationConstants.i18n_INSERT_A_VALID_EMAIL)}
              />
            </Col>
          </Row>
          <Row className={"label-row"}>
            <Col>
              <span className={"label"}>{t(TranslationConstants.i18n_PASSWORD)}</span>
            </Col>
          </Row>
          <Row className={"input-row"}>
            <Col>
              <Input.Password
                placeholder={t(TranslationConstants.i18n_PASSWORD)}
                value={password}
                onChange={(e) => {
                  setFailed(false)
                  setShowValidationErrors(false)
                  setPassword(e.target.value)
                }}
              />
              <InputErrorMessage
                show={showValidationErrors && !password}
                message={t(TranslationConstants.i18n_MANDATORY_FIELD)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                type={"primary"}
                className={"login-button"}
                onClick={() => {
                  performLogin()
                }}
              >
                {t(TranslationConstants.i18n_LOGIN)}
              </Button>
            </Col>
          </Row>
        </Col>
      </Spin>
    </Card>
  )
}

export { LoginForm }
