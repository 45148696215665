import React, { Component, ErrorInfo } from "react"
import "./JudoErrorBoundary.scss"
import { Page500 } from "frontend/views/500/500"
import { Page403 } from "../../views/403/403"
import { ErrorConstants } from "../../../common/constants/errors"

type JudoErrorBoundaryState = {
  error: Error | null
  errorInfo: ErrorInfo | null
}

class JudoErrorBoundary extends Component<any, JudoErrorBoundaryState> {
  constructor(props: any) {
    super(props)

    this.state = {
      error: null,
      errorInfo: null,
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
  }

  render() {
    const { error, errorInfo } = this.state
    const { children } = this.props

    if (error) {
      console.error(error)

      if (error.message === ErrorConstants.UNAUTHORIZED) {
        return <Page403 />
      }

      return <Page500 />
    }

    return children
  }
}

export default JudoErrorBoundary
