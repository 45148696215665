import React, { useContext, useMemo } from "react"
import "./JudoSider.scss"
import { Layout, Menu, MenuProps } from "antd"
const { Sider } = Layout
const { SubMenu } = Menu
import { useNavigate, useLocation } from "react-router-dom"
import { LayoutConstants } from "common/constants/layout"
import getNavigation from "frontend/getNavigation"
import judoDataLogo from "frontend/assets/images/logo-judodata.png"
import { PathConstants } from "common/constants/paths"
import { CollapseType } from "antd/es/layout/Sider"
import { AuthContext } from "../../../contexts/auth"
type MenuItem = Required<MenuProps>["items"][number]

function getItem({
  label,
  key,
  icon,
  className,
  disabled,
  children,
}: {
  label: React.ReactNode
  key?: React.Key | null
  icon?: React.ReactNode
  className?: string
  disabled?: boolean
  children?: MenuItem[]
}): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    className,
    disabled,
  } as MenuItem
}
function JudoSider({
  collapsed = undefined,
  isResponsive,
  onCollapse = () => {
    return
  },
}: {
  collapsed?: boolean
  isResponsive?: boolean
  onCollapse?: (collapsed: boolean, type?: CollapseType) => void
}) {
  const navigate = useNavigate()
  const location = useLocation()

  const { config } = useContext(AuthContext)

  const menuPaths: string[] = useMemo(() => {
    const menuPaths: string[] = []
    getNavigation(config!).forEach((navigationElement) => {
      if (navigationElement.paths) {
        menuPaths.push(...navigationElement.paths.map((p) => p.path))
      } else {
        menuPaths.push(navigationElement.path)
      }
    })

    return menuPaths
  }, [config])

  const menuCurrentKey = useMemo(() => {
    return menuPaths.reverse().find((path) => location.pathname.includes(path)) || location.pathname
  }, [menuPaths])

  const menuItems = useMemo(() => {
    return getNavigation(config!).map((navigationElement) => {
      if (!navigationElement.paths || navigationElement.paths.length === 0) {
        return getItem({
          disabled: navigationElement.disabled,
          key: navigationElement.path,
          icon: navigationElement.icon,
          label: navigationElement.name,
          className: navigationElement.className,
        })
      } else {
        return getItem({
          disabled: navigationElement.disabled,
          key: navigationElement.path,
          icon: navigationElement.icon,
          label: navigationElement.name,
          className: navigationElement.className,
          children: navigationElement.paths.map((p) => {
            return getItem({
              disabled: p.disabled,
              key: p.path,
              icon: p.icon,
              label: p.name,
              className: p.className,
            })
          }),
        })
      }
    }) as MenuItem[]
  }, [config])

  return (
    <Sider
      collapsible
      breakpoint="lg"
      collapsedWidth={LayoutConstants.JUDO_COLLAPSED_SIDER_WIDTH}
      onCollapse={(collapsed, type) => {
        onCollapse(collapsed, type)
      }}
      collapsed={collapsed}
      className={`judo-sider ${isResponsive ? "is-responsive" : ""}`}
    >
      <div className="logo">
        <img
          src={judoDataLogo}
          width="41"
          className="main-logo"
          height="50"
          alt="Judo Data"
          onClick={() => {
            navigate(PathConstants.DASHBOARD)
          }}
        />
      </div>
      <Menu
        theme="dark"
        selectedKeys={[menuCurrentKey]}
        defaultOpenKeys={
          collapsed
            ? []
            : getNavigation(config!)
                .filter((navigationElement) => {
                  return navigationElement.paths && navigationElement.paths?.length > 0
                })
                .map((navigationElement) => navigationElement.path)
        }
        mode="inline"
        onClick={(selectInfo) => {
          navigate(selectInfo.key)
        }}
        items={menuItems}
      />
    </Sider>
  )
}

export { JudoSider }
