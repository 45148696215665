import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTable,
  faGauge,
  faVideo,
  faDownload,
  faDatabase,
  faFistRaised,
  faTags,
  faHandPaper,
  faMedal,
  faUser,
  faPeopleArrowsLeftRight,
  faAlignJustify,
  faFilm,
  faUpload,
} from "@fortawesome/free-solid-svg-icons"
import i18n from "frontend/languages/index"
import { TranslationConstants } from "common/constants/translations"
import { PathConstants } from "../common/constants/paths"
import { KamaConfig } from "../common/types/kama/apis"

type NavigationElement = {
  path: string
  name: string
  disabled?: boolean
  className?: string
  icon: React.ReactNode
  paths?: NavigationElement[]
}

export function getNavigation(config: KamaConfig): NavigationElement[] {
  if (config) {
    return [
      {
        name: i18n.t(TranslationConstants.i18n_DASHBOARD),
        path: PathConstants.DASHBOARD,
        icon: <FontAwesomeIcon icon={faGauge} />,
      },
      {
        name: i18n.t(TranslationConstants.i18n_CMS),
        path: "cms",
        icon: <FontAwesomeIcon icon={faDatabase} />,
        paths: [
          config.cms.matches.lamp && {
            path: PathConstants.CMS_LAMP_COMPETITIONS,
            name: i18n.t(TranslationConstants.i18n_LAMP),
            icon: <FontAwesomeIcon icon={faTable} />,
          },
          config.cms.athletes.page && {
            path: PathConstants.CMS_ATHLETES_TABLE_PAGE,
            name: i18n.t(TranslationConstants.i18n_ATHLETES),
            icon: <FontAwesomeIcon icon={faFistRaised} />,
          },
          config.cms.referees.page && {
            path: PathConstants.CMS_REFEREES_TABLE_PAGE,
            name: i18n.t(TranslationConstants.i18n_REFEREES),
            icon: <FontAwesomeIcon icon={faHandPaper} />,
          },
          config.cms.competitions.page && {
            path: PathConstants.CMS_COMPETITIONS_TABLE_PAGE,
            name: i18n.t(TranslationConstants.i18n_COMPETITIONS),
            icon: <FontAwesomeIcon icon={faMedal} />,
          },
          config.cms.matches.page && {
            path: PathConstants.CMS_MATCHES_TABLE_PAGE,
            name: i18n.t(TranslationConstants.i18n_MATCHES),
            icon: <FontAwesomeIcon icon={faAlignJustify} />,
          },
        ].filter((e) => e) as NavigationElement[],
      },
      {
        path: "event-tagging",
        name: i18n.t(TranslationConstants.i18n_EVENT_TAGGING),
        icon: <FontAwesomeIcon icon={faTags} />,
        paths: [
          config.eventTagging.matches.page && {
            path: PathConstants.SELECT_MATCH,
            name: i18n.t(TranslationConstants.i18n_MATCHES),
            icon: <FontAwesomeIcon icon={faPeopleArrowsLeftRight} />,
          },
          config.eventTagging.exportCSV.page && {
            path: PathConstants.COMPETITION_EVENT_EXPORT_CSV,
            name: i18n.t(TranslationConstants.i18n_EXPORT_CSV),
            icon: <FontAwesomeIcon icon={faDownload} />,
          },
          config.eventTagging.uploadVideos.page && {
            path: PathConstants.MATCH_UPLOAD_VIDEOS,
            name: i18n.t(TranslationConstants.i18n_UPLOAD_VIDEOS),
            icon: <FontAwesomeIcon icon={faUpload} />,
          },
        ].filter((e) => e) as NavigationElement[],
      },
      {
        name: i18n.t(TranslationConstants.i18n_VIDEO_ANALYSIS),
        path: "video-analysis",
        icon: <FontAwesomeIcon icon={faVideo} />,
        paths: [
          {
            path: PathConstants.VIDEO_ANALYSIS_MATCHES,
            name: i18n.t(TranslationConstants.i18n_MATCHES),
            icon: <FontAwesomeIcon icon={faFilm} />,
          },
        ],
      },
      {
        name: i18n.t(TranslationConstants.i18n_PROFILE),
        className: "last-element",
        path: PathConstants.USER_PROFILE,
        icon: <FontAwesomeIcon icon={faUser} />,
      },
    ].filter((e) => !e.paths || e.paths.length !== 0)
  } else {
    return []
  }
}

export default getNavigation
