export const ErrorConstants = {
  ENTITY_NOT_FOUND_MESSAGE: "Entity not found",
  MISSING_REQUIRED_FIELDS_MESSAGE: "Missing required fields",
  OPERATION_FORBIDDEN_MESSAGE: "Operation forbidden",
  ENTITY_ALREADY_EXISTS_MESSAGE: "Entity already exists",
  RESOURCE_NOT_AVAILABLE: "Resource not available",
  ENTITIES_NOT_FOUND_MESSAGE: "Entities not found",
  AUTHORIZATION_FAILED_MESSAGE: "Authorization failed",
  GENERIC_ERROR_MESSAGE: "Generic error",
  ENTITY_ALREADY_PRESENT: "Entity already present",
  BAD_REQUEST: "Bad request",
  UNAUTHORIZED: "Unauthorized",
  FILE_ASSOCIATION_FAILED: "File association failed",
}
