export const APIConstants = {
  POST: "post",
  PUT: "put",
  GET: "get",
  PATCH: "patch",
  DELETE: "delete",
  AUTH_TOKEN_NAME: "token",
  AUTH_TOKEN_HEADER: "Authorization",
  ACCOUNT_JUDO_DATA_VERIFY_TOKEN: "/api/v1/judo-data/verify-token",
  ACCOUNT_JUDO_DATA_LOGIN: "/api/v1/judo-data/login",
  ACCOUNT_JUDO_DATA_LOGOUT: "/api/v1/judo-data/logout",
  WEIGHT_CLASSES_GET_ALL: "/api/v1/weightClasses",
  SEASONS_GET_ALL: "/api/v1/seasons",
  ROUNDS_GET_ALL: "/api/v1/rounds",
  AGE_CLASSES_GET_ALL: "/api/v1/ageClasses",
  OUTCOMES_GET_ALL: "/api/v1/outcomes",
  POINTS_GET_ALL: "/api/v1/points",
  ATHLETES_POST_SEARCH: "/api/v1/athletes/search",
  ATHLETES_POST_GET_PAGINATED: "/api/v1/athletes/getPaginated",
  ATHLETES_POST_CREATE_ATHLETE: "/api/v1/athletes",
  ATHLETES_PATCH_UPDATE_ATHLETE: "/api/v1/athletes/:athleteId",
  ATHLETES_GET_BY_ATHLETE_ID: "/api/v1/athletes/:athleteId",
  REFEREES_POST_GET_PAGINATED: "/api/v1/referees/getPaginated",
  REFEREES_GET_BY_REFEREE_ID: "/api/v1/referees/:refereeId",
  REFEREES_POST_CREATE_REFEREE: "/api/v1/referees",
  REFEREES_PATCH_UPDATE_REFEREE: "/api/v1/referees/:refereeId",
  DOCTOR_CALLS_GET_ALL: "/api/v1/doctorCalls",
  COMPETITION_LEVELS_GET_ALL: "/api/v1/competitionLevels",
  ATTEMPTS_GET_ALL: "/api/v1/attempts",
  ATTITUDES_GET_ALL: "/api/v1/attitudes",
  COACH_CARDS_GET_ALL: "/api/v1/coachCards",
  DIRECTIONS_GET_ALL: "/api/v1/directions",
  COUNTRIES_GET_ALL: "/api/v1/countries",
  HOLD_LATERALITIES_GET_ALL: "/api/v1/holdLateralities",
  KUMI_KATAS_GET_ALL: "/api/v1/kumiKatas",
  DATA_POST_UPDATE_MATCHES: "/api/v1/data/updateMatches",
  PENALTY_KINDS_GET_ALL: "/api/v1/penaltyKinds",
  PENALTIES_GET_ALL: "/api/v1/penalties",
  POSTURES_GET_ALL: "/api/v1/postures",
  SWITCHES_GET_ALL: "/api/v1/switches",
  TACHI_WAZAS_GET_ALL: "/api/v1/tachiWazas",
  TATAMI_AREAS_GET_ALL: "/api/v1/tatamiAreas",
  NE_WAZAS_GET_ALL: "/api/v1/neWazas",
  NE_WAZA_BODY_POSITIONS_GET_ALL: "/api/v1/neWazaBodyPositions",
  COMPETITIONS_GET_BY_COMPETITION_ID: "/api/v1/competitions/:competitionId",
  COMPETITIONS_GET_CSV_BY_COMPETITION_ID: "/api/v1/competitions/:competitionId/getCSV",
  COMPETITIONS_GET_ALL: "/api/v1/competitions",
  COMPETITIONS_POST_GET_PAGINATED: "/api/v1/competitions/getPaginated",
  COMPETITIONS_POST_CREATE_COMPETITION: "/api/v1/competitions",
  COMPETITIONS_PATCH_UPDATE_COMPETITION: "/api/v1/competitions/:competitionId",
  PROVISIONAL_COMPETITIONS_GET_ATHLETES: "/api/v1/provisionalCompetitions/:competitionId/getAthletes",
  PROVISIONAL_COMPETITIONS_GET_REFEREES: "/api/v1/provisionalCompetitions/:competitionId/getReferees",
  PROVISIONAL_COMPETITIONS_GET_MATCHES: "/api/v1/provisionalCompetitions/:competitionId/getMatches",
  PROVISIONAL_COMPETITIONS_POST_IMPORT_DATA: "/api/v1/provisionalCompetitions/:competitionId/importData",
  PROVISIONAL_COMPETITIONS_GET_ALL: "/api/v1/provisionalCompetitions",
  PROVISIONAL_COMPETITIONS_GET_BY_COMPETITION_ID: "/api/v1/provisionalCompetitions/:competitionId",
  PROVISIONAL_MATCHES_DELETE_BY_ID: "/api/v1/provisionalMatches/:provisionalMatchId",
  MATCHES_GET_BY_MATCH_ID: "/api/v1/matches/:matchId",
  MATCHES_GET_GET_MATCHES: "/api/v1/matches",
  MATCHES_POST_GET_PAGINATED: "/api/v1/matches/getPaginated",
  MATCHES_GET_EVENTS_BY_MATCH_ID: "/api/v1/matches/:matchId/getEvents",
  MATCHES_POST_CREATE_MATCH: "/api/v1/matches",
  MATCHES_POST_CREATE_MATCH_MANY: "/api/v1/matches/createMany",
  MATCHES_PATCH_UPDATE_MATCH: "/api/v1/matches/:matchId",
  JUDODATA_GET_MATCHES_GET_BY_MATCH_ID: "/api/v1/judoData/matches/:matchId",
  JUDODATA_GET_MATCHES_GET_EVENTS_BY_MATCH_ID: "/api/v1/judoData/matches/:matchId/getEvents",
  JUDODATA_POST_EVENTS_EXPLORE: "/api/v1/judoData/events/explore",
  JUDODATA_GET_PRIVATE_GET_ATHLETE_CSV: "/api/v1/judoData/private/getAthleteCSV",
  JUDODATA_GET_PRIVATE_GET_COMPETITION_CSV: "/api/v1/judoData/private/getCompetitionCSV",
  JUDODATA_GET_PRIVATE_GET_DIRECTION_CSV: "/api/v1/judoData/private/getDirectionCSV",
  JUDODATA_GET_PRIVATE_GET_MATCH_CSV: "/api/v1/judoData/private/getMatchCSV",
  JUDODATA_GET_PRIVATE_GET_NE_WAZA_CSV: "/api/v1/judoData/private/getNeWazaCSV",
  JUDODATA_GET_PRIVATE_GET_POINT_CSV: "/api/v1/judoData/private/getPointCSV",
  JUDODATA_GET_PRIVATE_GET_REFEREE_CSV: "/api/v1/judoData/private/getRefereeCSV",
  JUDODATA_GET_PRIVATE_GET_SWITCH_CSV: "/api/v1/judoData/private/getSwitchCSV",
  JUDODATA_GET_PRIVATE_GET_TACHI_WAZA_CSV: "/api/v1/judoData/private/getTachiWazaCSV",
  JUDODATA_GET_PRIVATE_GET_VIDEO_CSV: "/api/v1/judoData/private/getVideoCSV",
  EVENTS_POST_EVENT: "/api/v1/events",
  EVENTS_GET_GET_BY_MATCH_IDS: "/api/v1/events/getByMatchIds",
  EVENTS_POST_EXPLORE: "/api/v1/events/explore",
  EVENTS_DELETE_EVENT: "/api/v1/events/:eventId",
  EVENTS_DELETE_MANY_EVENT: "/api/v1/events",
  EVENTS_PATCH_EVENT: "/api/v1/events",
  EVENTS_PATCH_UPDATE_MANY_EVENT: "/api/v1/events/updateMany",
  VIDEOS_UPLOADS_INITIALIZE: "/api/v1/videos/uploads/initialize",
  VIDEOS_UPLOADS_EXTERNAL: "/api/v1/videos/uploads/external",
  VIDEOS_MOVE: "/api/v1/videos/move",
  VIDEOS_UPLOADS_GET_SIGNED_URLS: "/api/v1/videos/uploads/getSignedUrls",
  VIDEOS_UPLOADS_FINALIZE_UPLOAD: "/api/v1/videos/uploads/finalize",
  VIDEOS_DELETE_VIDEO: "/api/v1/videos/:videoId",
  LANGUAGE_HEADER: "judoLanguage",
} as const
