import React from "react"
import { PathConstants } from "../common/constants/paths"

const Dashboard = React.lazy(() => import("frontend/views/Dashboard/Dashboard"))
const MatchSelectorPage = React.lazy(() => import("frontend/views/MatchSelectorPage/MatchSelectorPage"))
const MatchVideoEventAnalysisSelection = React.lazy(
  () => import("frontend/views/MatchVideoEventAnalysisSelection/MatchVideoEventAnalysisSelection"),
)
const MatchUploadVideoPage = React.lazy(() => import("frontend/views/MatchUploadVideoPage/MatchUploadVideoPage"))
const MatchUploadManyVideosPage = React.lazy(
  () => import("frontend/views/MatchUploadManyVideosPage/MatchUploadManyVideosPage"),
)
const MatchTagPage = React.lazy(() => import("frontend/views/MatchTagPage/MatchTagPage"))
const UserProfile = React.lazy(() => import("frontend/views/UserProfile/UserProfile"))
const MatchEventAnalysis = React.lazy(() => import("frontend/views/MatchEventAnalysis/MatchEventAnalysis"))
const MatchVideoIframe = React.lazy(() => import("frontend/views/MatchVideoIframe/MatchVideoIframe"))
const EventExploreIframe = React.lazy(() => import("frontend/views/EventExploreIframe/EventExploreIframe"))
const CompetitionExportCSV = React.lazy(() => import("frontend/views/CompetitionExportCSV/CompetitionExportCSV"))

const CMSAthletesTablePage = React.lazy(() => import("frontend/views/CMS/Athletes/TablePage/CMSAthletesTablePage"))
const CMSAthletesCreatePage = React.lazy(() => import("frontend/views/CMS/Athletes/CreatePage/CMSAthletesCreatePage"))
const CMSAthletesUpdatePage = React.lazy(() => import("frontend/views/CMS/Athletes/UpdatePage/CMSAthletesUpdatePage"))

const CMSMatchesTablePage = React.lazy(() => import("frontend/views/CMS/Matches/TablePage/CMSMatchesTablePage"))
const CMSMatchesCreatePage = React.lazy(() => import("frontend/views/CMS/Matches/CreatePage/CMSMatchesCreatePage"))
const CMSMatchesUpdatePage = React.lazy(() => import("frontend/views/CMS/Matches/UpdatePage/CMSMatchesUpdatePage"))

const CMSCompetitionsTablePage = React.lazy(
  () => import("frontend/views/CMS/Competitions/TablePage/CMSCompetitionsTablePage"),
)
const CMSCompetitionsCreatePage = React.lazy(
  () => import("frontend/views/CMS/Competitions/CreatePage/CMSCompetitionsCreatePage"),
)
const CMSCompetitionsUpdatePage = React.lazy(
  () => import("frontend/views/CMS/Competitions/UpdatePage/CMSCompetitionsUpdatePage"),
)

const CMSRefereesTablePage = React.lazy(() => import("frontend/views/CMS/Referees/TablePage/CMSRefereesTablePage"))
const CMSRefereesCreatePage = React.lazy(() => import("frontend/views/CMS/Referees/CreatePage/CMSRefereesCreatePage"))
const CMSRefereesUpdatePage = React.lazy(() => import("frontend/views/CMS/Referees/UpdatePage/CMSRefereesUpdatePage"))

const CMSLAMPSelectCompetitionPage = React.lazy(
  () => import("frontend/views/CMS/LAMP/SelectCompetition/CMSLAMPSelectCompetitionPage"),
)
const CMSLAMPViewCompetitionPage = React.lazy(
  () => import("frontend/views/CMS/LAMP/ViewCompetition/CMSLAMPViewCompetitionPage"),
)
const CMSLAMPPopulateCompetitionWeightClassPage = React.lazy(
  () => import("frontend/views/CMS/LAMP/PopulateCompetitionWeightClass/CMSLAMPPopulateCompetitionWeightClassPage"),
)

const routes = [
  { path: PathConstants.DASHBOARD, component: Dashboard },
  { path: PathConstants.SELECT_MATCH, component: MatchSelectorPage },
  { path: PathConstants.MATCH_UPLOAD_VIDEO, component: MatchUploadVideoPage },
  { path: PathConstants.MATCH_UPLOAD_VIDEOS, component: MatchUploadManyVideosPage },
  { path: PathConstants.MATCH_TAG, component: MatchTagPage },
  { path: PathConstants.MATCH_TAG_MATCHES, component: MatchTagPage },
  { path: PathConstants.USER_PROFILE, component: UserProfile },
  { path: PathConstants.VIDEO_ANALYSIS_MATCH_EVENT_ANALYSIS, component: MatchEventAnalysis },
  { path: PathConstants.VIDEO_ANALYSIS_MATCHES, component: MatchVideoEventAnalysisSelection },
  { path: PathConstants.JUDODATA_MATCH_VIDEO, component: MatchVideoIframe },
  { path: PathConstants.JUDODATA_EVENTS_EXPLORE, component: EventExploreIframe },
  { path: PathConstants.COMPETITION_EVENT_EXPORT_CSV, component: CompetitionExportCSV },
  { path: PathConstants.CMS_ATHLETES_TABLE_PAGE, component: CMSAthletesTablePage },
  { path: PathConstants.CMS_ATHLETES_CREATE_PAGE, component: CMSAthletesCreatePage },
  { path: PathConstants.CMS_ATHLETES_UPDATE_PAGE, component: CMSAthletesUpdatePage },
  { path: PathConstants.CMS_REFEREES_TABLE_PAGE, component: CMSRefereesTablePage },
  { path: PathConstants.CMS_REFEREES_CREATE_PAGE, component: CMSRefereesCreatePage },
  { path: PathConstants.CMS_REFEREES_UPDATE_PAGE, component: CMSRefereesUpdatePage },
  { path: PathConstants.CMS_MATCHES_TABLE_PAGE, component: CMSMatchesTablePage },
  { path: PathConstants.CMS_MATCHES_CREATE_PAGE, component: CMSMatchesCreatePage },
  { path: PathConstants.CMS_MATCHES_UPDATE_PAGE, component: CMSMatchesUpdatePage },
  { path: PathConstants.CMS_COMPETITIONS_TABLE_PAGE, component: CMSCompetitionsTablePage },
  { path: PathConstants.CMS_COMPETITIONS_CREATE_PAGE, component: CMSCompetitionsCreatePage },
  { path: PathConstants.CMS_COMPETITIONS_UPDATE_PAGE, component: CMSCompetitionsUpdatePage },
  { path: PathConstants.CMS_LAMP_COMPETITIONS, component: CMSLAMPSelectCompetitionPage },
  { path: PathConstants.CMS_LAMP_COMPETITIONS_EDIT_PAGE, component: CMSLAMPViewCompetitionPage },
  {
    path: PathConstants.CMS_LAMP_COMPETITIONS_WEIGHT_CLASS_POPULATION_PAGE,
    component: CMSLAMPPopulateCompetitionWeightClassPage,
  },
]

export default routes
