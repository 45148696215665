import React from "react"
import "./Login.scss"
import { LoginForm } from "../../components/Forms/LoginForm/LoginForm"
import { useNavigate } from "react-router-dom"

function Login() {
  const navigate = useNavigate()

  return (
    <div className={"login-div"}>
      <LoginForm onLoginCompleted={() => navigate(0)} />
    </div>
  )
}

export { Login }
