import { kamaAccountApi } from "./axiosConfig"
import { APIConstants } from "common/constants/apis"
import { KamaLoginInput, KamaVerifyTokenOutput } from "common/types/kama/apis"

export const AuthAPI = {
  login: async function (loginInput: KamaLoginInput) {
    await kamaAccountApi.request({
      method: APIConstants.POST,
      url: APIConstants.ACCOUNT_JUDO_DATA_LOGIN,
      data: loginInput,
    })
  },
  verifyToken: async function (): Promise<KamaVerifyTokenOutput> {
    const response = await kamaAccountApi.request({
      url: APIConstants.ACCOUNT_JUDO_DATA_VERIFY_TOKEN,
      method: APIConstants.GET,
    })

    return response.data
  },
  logout: async function () {
    await kamaAccountApi.request({
      method: APIConstants.POST,
      url: APIConstants.ACCOUNT_JUDO_DATA_LOGOUT,
    })
  },
}
