import React from "react"
import "./404.scss"
import { useNavigate } from "react-router-dom"
import { Result, Button } from "antd"
import { JudoContainer } from "frontend/components/Layout/JudoContainer/JudoContainer"
import { useTranslation } from "react-i18next"
import { TranslationConstants } from "common/constants/translations"
import { HomeOutlined, ReloadOutlined } from "@ant-design/icons"
import { PathConstants } from "../../../common/constants/paths"

function Page404() {
  const navigate = useNavigate()

  const { t } = useTranslation()

  return (
    <JudoContainer>
      <Result
        status="404"
        title="404"
        subTitle={t(TranslationConstants.i18n_PAGE_NOT_FOUND)}
        extra={
          <div className={"extra-buttons-div-404"}>
            <Button type="primary" onClick={() => navigate(PathConstants.DASHBOARD)} icon={<HomeOutlined />}>
              {t(TranslationConstants.i18n_BACK_TO_HOME)}
            </Button>
            <Button type="primary" onClick={() => navigate(0)} icon={<ReloadOutlined />}>
              {t(TranslationConstants.i18n_RELOAD_PAGE)}
            </Button>
          </div>
        }
      />
    </JudoContainer>
  )
}

export { Page404 }
