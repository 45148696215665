import axios from "axios"
import { APIConstants } from "common/constants/apis"
import { LanguageConstants } from "common/constants/languages"
import i18n from "frontend/languages/index"

const api = axios.create()
export default api

const defaultJudoHeaders = {
  [APIConstants.LANGUAGE_HEADER]: i18n.language,
}

export const judoApi = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  headers: {
    ...defaultJudoHeaders,
  },
})

export const judoApiForJudoData = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  headers: {
    [APIConstants.LANGUAGE_HEADER]: "en",
  },
})

export const kamaAccountApi = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_KAMA_ACCOUNT_BASE_URL,
  headers: {
    "kama-language": LanguageConstants.DEFAULT_LANGUAGE,
  },
})

const errorHandler = (error: any) => {
  const statusCode = error.response ? error.response.status : null

  if (statusCode !== 401) {
    console.error(error)
  }

  return Promise.reject(error)
}

judoApi.interceptors.response.use(undefined, (error) => {
  return errorHandler(error)
})

kamaAccountApi.interceptors.response.use(undefined, (error) => {
  return errorHandler(error)
})
