import React from "react"
import { App } from "./App"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import JudoErrorBoundary from "./components/JudoErrorBoundary/JudoErrorBoundary"
import { createRoot } from "react-dom/client"

function AppWithCallbackAfterRender() {
  return (
    <BrowserRouter>
      <JudoErrorBoundary>
        <App />
      </JudoErrorBoundary>
    </BrowserRouter>
  )
}

const container = document.getElementById("root")!
const root = createRoot(container)
root.render(<AppWithCallbackAfterRender />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
